/* reduce z-index of the cookie consent banner to display toast notifications over it */
div[id='onetrust-banner-sdk'] {
  z-index: 1399 !important;
}

/* remove the app scroll introduced by chatwoot span in firefox
can be removed if this pr is completed https://github.com/chatwoot/chatwoot/pull/8754 */
div[class='woot--bubble-holder'] {
  overflow: hidden;
}
